<template>
  <section class="pt-50 pb-5">
    <div
      class="container pt-5 align-items-center justify-content-center m-auto login"
    >
      <validation-observer v-slot="{ handleSubmit }">
        <div class="card m-auto col-md-3 p-5">
          <div class="text-center intro">
            <img src="@/assets/img/logo.png" width="160" class="m-auto" />
            <span class="d-block account">Don't have account yet?</span>
            <span class="contact">
              <router-link :to="{ name: 'register' }" class="mail fw-bold"
                >Register Now</router-link
              ></span
            >
          </div>
          <div class="mt-4 text-center">
            <h4>Log In.</h4>
            <span>Login with your admin credentials</span>
            <div class="mt-3 inputbox">
              <validation-provider
                name="Username"
                rules="required"
                v-slot="{ errors }"
              >
                <input
                  type="text"
                  class="form-control"
                  name=""
                  placeholder="Username"
                  v-model="credentials.username"
                />
                <i class="fa fa-user"></i>

                <p class=" text-danger">{{ errors[0] }}</p>
              </validation-provider>
            </div>
            <div class="inputbox">
              <validation-provider
                name="Password"
                rules="required"
                v-slot="{ errors }"
              >
                <input
                  type="password"
                  class="form-control"
                  name=""
                  placeholder="Password"
                  v-model="credentials.password"
                />
                <i class="fa fa-lock"></i>
                <p class="text-danger">
                  {{ errors[0] }}
                </p>
              </validation-provider>
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <router-link :to="{ name: 'password-reset-request' }">
              Forgot Password?
            </router-link>
          </div>
          <div class="mybtn m-auto mt-3">
            <button
              v-if="!loading"
              type="submit"
              class="btn btn-primary rounded-0 px-2 mb-2 mb-lg-0 me-3 addnewbtn allbtn"
            >
              <span
                class="d-flex align-items-center"
                @click.prevent="handleSubmit(login)"
              >
                <span class="small">Login Now</span>
              </span>
            </button>
            <button
              v-else
              class="btn btn-primary rounded-0 px-2 mb-2 mb-lg-0 me-3 addnewbtn allbtn"
              type="button"
              disabled
            >
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Loading...
            </button>
            <button
              class="btn btn-danger border-0 rounded-0 px-2 mb-2 mb-lg-0 "
              @click.prevent="resetFields"
            >
              <span class="d-flex align-items-center">
                <span class="small">Reset</span>
              </span>
            </button>
          </div>
        </div>
      </validation-observer>
    </div>
  </section>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      credentials: {
        username: "",
        password: "",
      },
      loading: false,
    };
  },
  methods: {
    async login() {
      this.loading = true;
      await this.$store.dispatch("auth/login", this.credentials).then(() => {
        setTimeout(() => {
          this.loading = false;
        }, 2000);
      });
    },
    resetFields() {
      this.credentials = {
        username: "",
        password: "",
      };
    },
  },
};
</script>

<style scoped></style>
